import { STATUSES, LOGIN_STATUSES } from '../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL, authHeader, Logout_clear } from '../app/Other/Constant';
import Swal from 'sweetalert2';
import Spinner from '../app/shared/Spinner';

const { createSlice } = require('@reduxjs/toolkit');

axios.defaults.headers.common = authHeader();

console.log("axios.defaults.headers.common", axios.defaults.headers);
axios.interceptors.response.use((response) => response, (error) => {
    // console.log("Cathc response");
    console.log("Cathc eorr",error);
    if (error.response.status == 401 || error.response.status == 403) {
        // sessionStorage.removeItem("loginDetails");
        // Swal.fire("")
        Logout_clear();
        Swal.fire({
            title: 'Your Session is expired. Please login again!',
        }).then((result) => {
            window.location.reload();
            window.location.href = "/login";
        })
    }
    throw error;
});


const LoginSlice = createSlice({
    name: "Login",
    initialState: {
        LoginData: [],
        LoginStatus: STATUSES.IDLE,
        ResetPasswordData: []
    },
    reducers: {
        setLogin(state, action) {
            state.LoginData = action.payload
        },
        setStatus(state, action) {
            state.LoginStatus = action.payload;
        },
        ResetPassword(state, action) {
            state.ResetPasswordData = action.payload;
        },
    }
});

export const { setLogin, setStatus, ResetPassword } = LoginSlice.actions;
export default LoginSlice.reducer;


export function checkLoginAction(username, password) {
    return async function checkLoginThunk(dispatch, getState) {
        dispatch(setStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "login/checkUserLogin", { 'username': username, 'password': password })
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(setStatus(STATUSES.IDLE));
                    if (res.data.status == "WRONGIDPASS") {
                        dispatch(setLogin(res.data))
                    } else {
                        dispatch(setLogin(res.data))
                        sessionStorage.setItem("loginDetails", JSON.stringify(res.data));
                        sessionStorage.removeItem("isJoinChargesCompleted");
                        sessionStorage.removeItem("CreditAccess");
                        if (res.data[0].length != 0) {
                            console.log("res.data", res.data)
                            console.log("res.data", res.data[2]['token'])
                            if (res.data[0].userTypeName == "SuperAdmin" || res.data[0].userTypeName == "Agent" || res.data[0].userTypeName == "Financer") {
                                var currentLocation = window.location;
                                window.location.reload();
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUSES.ERROR));
        }
    }
}


export function ResetPasswordAction(data) {
    return async function ResetPasswordThunk(dispatch, getState) {
        dispatch(setStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "login/forgotpassword", data)
                .then(res => {
                    debugger;
                    console.log("res.data", res.data)
                    if (res.data.status == "WRONGIDPASS") {
                        dispatch(ResetPassword(res.data))
                    } else {
                        dispatch(ResetPassword(res.data))
                    }
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUSES.ERROR));
        }
    }
}