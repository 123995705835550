import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const FinancerCustomerSearchSlice = createSlice({
    name: "FinancerCustomerSearch",
    initialState: {
        Status: STATUSES.IDLE,
        getAllCustomerFinanceData: [],
        getAllCustomerHistoryData: [],
        GetCustomerWitnessData: [],
        ExcelCustomerSearchData:[]
    },
    reducers: {
        getAllCustomerFinanceDatabyId(state, action) {
            state.getAllCustomerFinanceData = action.payload
        },
        searchCustomerStatus(state, action) {
            state.Status = action.payload;
        },
        searchCustomerHistory(state, action) {
            state.getAllCustomerHistoryData = action.payload;
        },
        searchCustomerWitnessData(state, action) {
            state.GetCustomerWitnessData = action.payload;
        },
        getExcelCustomerSearchData(state, action) {
            state.ExcelCustomerSearchData = action.payload;
        },
    }
});

export const { searchCustomerStatus, getAllCustomerFinanceDatabyId, searchCustomerHistory,searchCustomerWitnessData,getExcelCustomerSearchData } = FinancerCustomerSearchSlice.actions;
export default FinancerCustomerSearchSlice.reducer;

export function getAllCustomerFinanceDatabyIdAction(data) {
    return async function getAllCustomerFinanceDatabyIdThunk(dispatch, getState) {
        dispatch(searchCustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financesearchcustomer/getcustomersearchdata", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getAllCustomerFinanceDatabyId(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(searchCustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(searchCustomerStatus(STATUSES.ERROR));
        }
    }
}

export function searchCustomerHistoryAction(data) {
    return async function searchCustomerHistoryThunk(dispatch, getState) {
        dispatch(searchCustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financesearchcustomer/getcustomersearchhistory", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(searchCustomerHistory(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(searchCustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(searchCustomerStatus(STATUSES.ERROR));
        }
    }
}


export function searchCustomerWitnessDataAction(data) {
    return async function searchCustomerWitnessDataThunk(dispatch, getState) {
        dispatch(searchCustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financesearchcustomer/getcustomersearchwitnessdata", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(searchCustomerWitnessData(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(searchCustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(searchCustomerStatus(STATUSES.ERROR));
        }
    }
}

export function getExcelCustomerSearchDataAction(data) {
    return async function getExcelCustomerSearchDataThunk(dispatch, getState) {
        dispatch(searchCustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financesearchcustomer/getexcelcustomersearchdata", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getExcelCustomerSearchData(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(searchCustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(searchCustomerStatus(STATUSES.ERROR));
        }
    }
}
