import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');


const FinancerExcelAccountSlice = createSlice({
    name: "FinancerExcelAccount",
    initialState: {
        FinancerExcelAccountData: [],
        Status: STATUSES.IDLE,
        getExcelAccountData: [],
        UpdateExcelStatusData: [],
        UpdateExcelAgentData: [],
        updateExcelAgentWithBuilkData: [],
        DownloadNarrationDatesData: [],
        AddUpdateExcelColumnData: [],
        GetExcelColumnData:[],
        UpdateExcelWitnessData:[],
        UpdateExcelWitnessTwoData:[]
    },
    reducers: {
        insertUpdateExcelAccount(state, action) {
            state.FinancerExcelAccountData = action.payload
        },
        getExcelAccountList(state, action) {
            state.getExcelAccountData = action.payload
        },
        insertUpdateExcelStatus(state, action) {
            state.Status = action.payload
        },
        UpdateExcelStatus(state, action) {
            state.UpdateExcelStatusData = action.payload
        },
        UpdateExcelAgent(state, action) {
            state.UpdateExcelAgentData = action.payload
        },
        updateExcelAgentWithBuilk(state, action) {
            state.updateExcelAgentWithBuilkData = action.payload
        },
        DownloadNarrationDates(state, action) {
            state.DownloadNarrationDatesData = action.payload
        },
        AddUpdateExcelColumn(state, action) {
            state.AddUpdateExcelColumnData = action.payload
        },
        GetExcelColumn(state, action) {
            state.GetExcelColumnData = action.payload
        },
        UpdateExcelWitness(state, action){
            state.UpdateExcelWitnessData = action.payload
        },
        UpdateExcelWitnessTwo(state,action){
            state.UpdateExcelWitnessTwoData = action.payload
        }
    }
});

export const {
    insertUpdateExcelAccount,
    insertUpdateExcelStatus,
    getExcelAccountList,
    UpdateExcelStatus,
    UpdateExcelAgent,
    updateExcelAgentWithBuilk,
    DownloadNarrationDates,
    AddUpdateExcelColumn,
    GetExcelColumn,
    UpdateExcelWitness,
    UpdateExcelWitnessTwo
} = FinancerExcelAccountSlice.actions;
export default FinancerExcelAccountSlice.reducer;

export function insertUpdateExcelAccountAction(data) {
    console.log({data})
    // alert("call insertUpdateExcelAccountAction")
    return async function insertUpdateExcelAccountThunk(dispatch, getState) {
        dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/financerexcelaccountaddupdate", data)
                .then(res => {
                    debugger;
                    console.log("financerexcelaccountaddupdate", res)
                    dispatch(insertUpdateExcelAccount(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}


export function getExcelAccountListAction(data) {
    return async function getExcelAccountListThunk(dispatch, getState) {
        dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/getexcelaccountreport", data)
                .then(res => {
                    debugger;
                    console.log("getexcelaccountreport", res)
                    dispatch(getExcelAccountList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateExcelStatusAction(data) {
    return async function UpdateExcelStatusThunk(dispatch, getState) {
        dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/updateexcelstatus", data)
                .then(res => {
                    debugger;
                    console.log("UpdateExcelStatus", res)
                    dispatch(UpdateExcelStatus(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateExcelAgentAction(data) {
    return async function UpdateExcelAgentThunk(dispatch, getState) {
        dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/updateexcelagent", data)
                .then(res => {
                    debugger;
                    console.log("updateexcelagent", res)
                    dispatch(UpdateExcelAgent(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}

export function updateExcelAgentWithBuilkAction(data) {
    return async function updateExcelAgentWithBuilkThunk(dispatch, getState) {
        dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/updateexcelagentwithbuilk", data)
                .then(res => {
                    debugger;
                    console.log("updateexcelagent", res)
                    dispatch(updateExcelAgentWithBuilk(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}

export function getNarrationDatesAction(data) {
    return async function getNarrationDatesThunk(dispatch, getState) {
        dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/getnarrationdates", data)
                .then(res => {
                    debugger;
                    console.log("updateexcelagent", res)
                    dispatch(DownloadNarrationDates(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}

export function AddUpdateExcelColumnAction(data) {
    return async function AddUpdateExcelColumnThunk(dispatch, getState) {
        dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/addupdateexcelcolumn", data)
                .then(res => {
                    debugger;
                    console.log("updateexcelagent", res)
                    dispatch(AddUpdateExcelColumn(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}

export function GetExcelColumnAction(data) {
    return async function GetExcelColumnThunk(dispatch, getState) {
        dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/getexcelcolumn", data)
                .then(res => {
                    console.log("updateexcelagent", res)
                    dispatch(GetExcelColumn(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateExcelWitnessAction(data) {
    return async function UpdateExcelWitnessThunk(dispatch, getState) {
        // dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/updateexcelwitness", data)
                .then(res => {
                    console.log("UpdateExcelWitness", res)
                    dispatch(UpdateExcelWitness(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            // dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateExcelWitnessTwoAction(data) {
    return async function UpdateExcelWitnessTwoThunk(dispatch, getState) {
        // dispatch(insertUpdateExcelStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerexcelaccount/updateexcelwitnesstwo", data)
                .then(res => {
                    console.log("updateexcelwitnesstwo", res)
                    dispatch(UpdateExcelWitnessTwo(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            // dispatch(insertUpdateExcelStatus(STATUSES.ERROR));
        }
    }
}