import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const AdminCustomerSlice = createSlice({
    name: "AdminCustomer",
    initialState: {
        getCustomerListData: [],
        Status: STATUSES.IDLE,
    },
    reducers: {
        getCustomerAdminList(state, action) {
            state.getCustomerListData = action.payload
        },
        AdminCustomerStatus(state, action) {
            state.status = action.payload;
        }
    }
});

export const { getCustomerAdminList, AdminCustomerStatus } = AdminCustomerSlice.actions;
export default AdminCustomerSlice.reducer;


export function getCustomerAdminListAction(data) {
    return async function getCustomerAdminListThunk(dispatch, getState) {
        dispatch(AdminCustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "admincustomer/getcustomerlist", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCustomerAdminList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(AdminCustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminCustomerStatus(STATUSES.ERROR));
        }
    }
}


