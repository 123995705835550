import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const BankDetailsSlice = createSlice({
    name: "BankDetails",
    initialState: {
        getBankDetailsListData: [],
        Status: STATUSES.IDLE,
        updateBankDetailsData: []
    },
    reducers: {
        getBankDetailsList(state, action) {
            state.getBankDetailsListData = action.payload
        },
        BankDetailsStatus(state, action) {
            state.status = action.payload;
        },
        UpdateBankDetails(state, action) {
            state.updateBankDetailsData = action.payload;
        }
    }
});

export const { getBankDetailsList, BankDetailsStatus, UpdateBankDetails } = BankDetailsSlice.actions;
export default BankDetailsSlice.reducer;


export function getBankDetailsAction() {
    return async function getBankDetailsThunk(dispatch, getState) {
        dispatch(BankDetailsStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "adminbankdetails/getadminbankdetails")
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getBankDetailsList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(BankDetailsStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(BankDetailsStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateBankDetailsAction(data) {
    return async function UpdateBankDetailsThunk(dispatch, getState) {
        dispatch(BankDetailsStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "adminbankdetails/updateadminbankdetails",data)
                .then(res => {
                    debugger;
                    console.log("updateBankDetailsData",res.data)
                    dispatch(UpdateBankDetails(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(BankDetailsStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(BankDetailsStatus(STATUSES.ERROR));
        }
    }
}


