import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const FinanceCollectionSlice = createSlice({
    name: "FinanceCollection",
    initialState: {
        Status: STATUSES.IDLE,
        getCollectionByIdData: [],
        insertCollectionByIdData: [],
        insertCollectionLoanDefaultData: [],
        CollectionTransactionDeleteData: []
    },
    reducers: {
        getCollectionById(state, action) {
            state.getCollectionByIdData = action.payload
        },
        insertCollectionById(state, action) {
            state.insertCollectionByIdData = action.payload
        },
        CollectionStatus(state, action) {
            state.status = action.payload;
        },
        insertCollectionLoanDefault(state, action) {
            state.insertCollectionLoanDefaultData = action.payload;
        },
        CollectionTransactionDelete(state, action) {
            state.CollectionTransactionDeleteData = action.payload
        }
    }
});

export const { getCollectionById, CollectionStatus, insertCollectionById, insertCollectionLoanDefault, CollectionTransactionDelete } = FinanceCollectionSlice.actions;
export default FinanceCollectionSlice.reducer;

export function getCollectionByIdAction(data) {
    return async function getCollectionByIdThunk(dispatch, getState) {
        dispatch(CollectionStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecollection/collectiondatagetbyid", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCollectionById(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(CollectionStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CollectionStatus(STATUSES.ERROR));
        }
    }
}


export function insertCollectionByIdAction(data) {
    return async function insertCollectionByIdThunk(dispatch, getState) {
        dispatch(CollectionStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecollection/collectioninsertaccount", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(insertCollectionById(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(CollectionStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CollectionStatus(STATUSES.ERROR));
        }
    }
}

export function CollectionInsertDefaultLoanAccountAction(data) {
    return async function CollectionInsertDefaultLoanAccountThunk(dispatch, getState) {
        dispatch(CollectionStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecollection/collectioninsertdefaultloanaccount", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(insertCollectionLoanDefault(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CollectionStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CollectionStatus(STATUSES.ERROR));
        }
    }
}

export function CollectionTransactionDeleteAction(data) {
    return async function CollectionTransactionDeleteThunk(dispatch, getState) {
        dispatch(CollectionStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecollection/collectionRemoveTransaction", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(CollectionTransactionDelete(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CollectionStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CollectionStatus(STATUSES.ERROR));
        }
    }
}

