import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import productReducer from './productSlice';
import LoginReducer from './LoginSlice';
import SendMailReducer from './SendMailSlice';
import FinancerCustomerReducer from './FinancerSlice/FinancerCustomerSlice';
import FinanceFinancerReducer from './FinancerSlice/FinanceFinancerSlice';
import FinancerCommanReducer from './FinancerSlice/FinancerCommanSlice';
import FinancerAccountReducer from './FinancerSlice/FinancerAccountSlice';
import FinanceCollectionReducer from './FinancerSlice/FinanceCollectionSlice';
import FinanceCreditReducer from './FinancerSlice/FinanceCreditSlice';
import FinancerCustomerSearchReducer from './FinancerSlice/FinancerCustomerSearchSlice';
import FinancerProfileReducer from './FinancerSlice/FinancerProfileSlice';
import FinancerDailyReportReducer from './FinancerSlice/FinancerDailyReportSlice';
import FinancerExcelAccountReducer from './FinancerSlice/FinancerExcelAccountSlice';
import FinancerRecoveryReducer from './FinancerSlice/FinancerRecoverySlice';

// Admin
import AdminFinancerReducer from './AdminSlice/AdminFinancerSlice';
import AdminCustomerReducer from './AdminSlice/AdminCustomerSlice';
import AdminAdminCashRequestReducer from './AdminSlice/AdminAdminCashRequestSlice';
import AdminChargesReducer from './AdminSlice/AdminChargesSlice';
import BankDetailsReducer from './AdminSlice/BankDetailsSlice';
import FinancerAgentReducer from './FinancerSlice/FinancerAgentSlice';
import AdminAgentReducer from './AdminSlice/AdminAgentSlice';
import AdminDashboardReducer from './AdminSlice/AdminDashboardSlice';

const store = configureStore({
    reducer: {
        cart: cartReducer,
        product: productReducer,
        Login: LoginReducer,
        FinancerCustomer: FinancerCustomerReducer,
        FinanceFinancer: FinanceFinancerReducer,
        FinancerComman: FinancerCommanReducer,
        FinancerAccount: FinancerAccountReducer,
        FinanceCollection: FinanceCollectionReducer,
        SendMail: SendMailReducer,
        FinanceCredit: FinanceCreditReducer,
        AdminFinancer: AdminFinancerReducer,
        AdminCustomer: AdminCustomerReducer,
        AdminCashRequest: AdminAdminCashRequestReducer,
        AdminCharges: AdminChargesReducer,
        FinancerCustomerSearch: FinancerCustomerSearchReducer,
        BankDetails: BankDetailsReducer,
        FinancerProfile: FinancerProfileReducer,
        FinancerDailyReport: FinancerDailyReportReducer,
        FinancerAgent: FinancerAgentReducer,
        AdminAgent: AdminAgentReducer,
        AdminDashboard: AdminDashboardReducer,
        FinancerExcelAccount: FinancerExcelAccountReducer,
        FinancerRecovery:FinancerRecoveryReducer
    },
});

export default store;
