import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import BaseAccess from './BaseAccess';
import BasePages from './BasePages';
import Spinner from '../app/shared/Spinner';
const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const FinanceCustomer = lazy(() => import('./Components/Financer/Customer/Customer'))
const FinanceAddCustomer = lazy(() => import('./Components/Financer/Customer/AddCustomer'))
const FinanceViewCustomerAccount = lazy(() => import('./Components/Financer/Customer/ViewCustomerAccount'))
const FinanceFinancer = lazy(() => import('./Components/Financer/Financer/Financer'))
const FinanceAddFinancer = lazy(() => import('./Components/Financer/Financer/AddFinancer'))
const FinanceCollection = lazy(() => import('./Components/Financer/Collection/Collection'))
const FinanceAccount = lazy(() => import('./Components/Financer/Account/Account'))
const FinanceAccountList = lazy(() => import('./Components/Financer/Account/AccountList'))
const FinanceAccountTransactionList = lazy(() => import('./Components/Financer/Account/AccountTransactionList'))
const FinanceAgent = lazy(() => import('./Components/Financer/Agent/Agent'))
const SearchCustomer = lazy(() => import('./Components/Financer/SearchCustomer/SearchCustomer'))
const FinanceCredit = lazy(() => import('./Components/Financer/Credit/Credit'))
const FinanceProfile = lazy(() => import('./Components/Financer/Profile/Profile'))
const FinanceAccountEdit = lazy(() => import('./Components/Financer/Account/AccountEdit'))
const FinanceDailyReport = lazy(() => import('./Components/Financer/Reports/DailyReport'))
const FinanceAddAgent = lazy(() => import('./Components/Financer/Agent/AddAgent'))
const FinanceExcelImport = lazy(() => import('./Components/Financer/ExcelImport/ExcelImport'))
const FinanceRecovery = lazy(() => import('./Components/Financer/Recovery/Recovery'))
const FinanceAddAgentBuik = lazy(() => import('./Components/Financer/ExcelImport/AddAgentBuik'))

const FinanceExcelColumnSet = lazy(() => import('./Components/Financer/ExcelImport/ExcelColumnSet'))

const ReactDataTable = lazy(() => import('./UserControl/RecatTable-7/ReactDataTable'));



const Login = lazy(() => import('./Components/Login/Login'));
const ResetPassword = lazy(() => import('./Components/ResetPassword/ResetPassword'));

const AdminFinancerList = lazy(() => import('./Components/Admin/Financer/FinancerList'));
const AdminCustomerList = lazy(() => import('./Components/Admin/Customer/CustomerList'));
const AdminCashRequest = lazy(() => import('./Components/Admin/CashRequest/CashRequest'));
const AdminCharges = lazy(() => import('./Components/Admin/Charges/Charges'));
const BankDetails = lazy(() => import('./Components/Admin/BankDetails/BankDetails'));
const AdminCredit = lazy(() => import('./Components/Admin/Credit/Credit'));
const AdminFinancerCustomerList = lazy(() => import('./Components/Admin/Financer/CustomerList'));
const AdminAgent = lazy(() => import('./Components/Admin/Agent/Agent'));
const AdminDashboard = lazy(() => import('./Components/Admin/Dashboard/Dashboard'));

class AppRoutes extends Component {
  render() {
    var currentUser = JSON.parse(sessionStorage.getItem("loginDetails"));
    console.log("currentUser", currentUser)
    var temp = false;
    const checkAgentPermission = (data) => {
      console.log("checkAgentPermission", data)
      let checkTemp = [];
      checkTemp = currentUser[1].filter((iteam) => iteam.menuID == data);
      if (checkTemp.length != 0) {
        console.log("checkAgentPermission", 'TRUE')
        return true;
      }
      console.log("checkAgentPermission", 'FALSE')
      return false;
    }
    // let navbarComponent = !this.state.isFullPageLayout ? <Navbar/> : '';
    return (<>
      {currentUser == null ?
        <BasePages>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Redirect to="/login" component={Login} />
            </Switch>
          </Suspense>
        </BasePages>
        :
        <>
          {
            currentUser[0].userTypeName == "Financer" || currentUser[0].userTypeName == "Agent" ?
              <>
                <BaseAccess>
                  <Suspense fallback={<Spinner />}>
                    <Switch>
                      <Route exact path="/dashboard" component={Dashboard} />
                      <Route exact path="/profile" component={FinanceProfile} />
                      <Route exact path="/excel" component={FinanceExcelImport} />
                      <Route exact path="/excelcolumn" component={FinanceExcelColumnSet} />
                      <Route exact path="/credit" component={FinanceCredit} />
                      <Route exact path="/recovery" component={FinanceRecovery} />
                      <Route exact path="/agent-builk" component={FinanceAddAgentBuik} />
                      {currentUser[0].isJoinChargesCompleted == 1 ?
                        <>
                          {
                            checkAgentPermission(5) == true &&
                            <>
                              <Route exact path="/dashboard" component={Dashboard} />
                            </>
                          }
                          {
                            checkAgentPermission(6) == true &&
                            <>
                              <Route exact path="/customer" component={FinanceCustomer} />
                              <Route exact path="/customer/add/:id?" component={FinanceAddCustomer} />
                              <Route exact path="/customer/account/:id" component={FinanceViewCustomerAccount} />
                              <Route exact path="/customer/account/transaction/:id" component={FinanceAccountTransactionList} />
                            </>
                          }
                          {
                            checkAgentPermission(7) == true &&
                            <>
                              <Route exact path="/financer" component={FinanceFinancer} />
                              <Route exact path="/financer/add/:id?" component={FinanceAddFinancer} />
                            </>
                          }
                          {
                            checkAgentPermission(8) == true &&
                            <>
                              <Route exact path="/collection" component={FinanceCollection} />
                            </>
                          }
                          {
                            checkAgentPermission(9) == true &&
                            <>
                              <Route exact path="/account" component={FinanceAccountList} />
                              <Route exact path="/account/add" component={FinanceAccount} />
                              <Route exact path="/account/edit/:id" component={FinanceAccountEdit} />
                              <Route exact path="/account/transaction/:id" component={FinanceAccountTransactionList} />
                            </>
                          }
                          {
                            checkAgentPermission(10) == true &&
                            <>
                              <Route exact path="/agent" component={FinanceAgent} />
                              <Route exact path="/agent/add/:id?" component={FinanceAddAgent} />
                            </>
                          }
                          {
                            checkAgentPermission(11) == true &&
                            <>
                              <Route exact path="/searchcustomer" component={SearchCustomer} />
                            </>
                          }
                          {
                            checkAgentPermission(13) == true &&
                            <>
                              <Route exact path="/daily-report" component={FinanceDailyReport} />
                            </>
                          }
                          {/* <Redirect from="*" to="/" /> */}
                        </>
                        : null
                      }
                      <Redirect to="/dashboard" />
                    </Switch>
                  </Suspense>
                </BaseAccess>
              </>
              : ""}
          {
            currentUser[0].userTypeName == "SuperAdmin" ?
              <><BaseAccess>
                <Suspense fallback={<Spinner />}>
                  <Switch>
                    <Route exact path="/dashboard" component={AdminDashboard} />
                    <Route exact path="/customer" component={AdminCustomerList} />
                    <Route exact path="/customer/add/:id?" component={FinanceAddCustomer} />
                    <Route exact path="/financer" component={AdminFinancerList} />
                    <Route exact path="/financer/add/:id?" component={FinanceAddFinancer} />
                    <Route exact path="/financer/customer/:id" component={AdminFinancerCustomerList} />
                    <Route exact path="/cashrequest" component={AdminCashRequest} />
                    {/* <Route exact path="/account/:id?" component={FinanceAccountList} /> */}
                    {/* <Route exact path="/account/transaction/:id" component={FinanceAccountTransactionList} /> */}
                    <Route exact path="/charges" component={AdminCharges} />
                    <Route exact path="/bankdetails" component={BankDetails} />
                    <Route exact path="/credit" component={AdminCredit} />
                    <Route exact path="/agent" component={AdminAgent} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Redirect to="/dashboard" />
                  </Switch>
                </Suspense>
              </BaseAccess>
              </>
              : ""}
        </>
      }
    </>
    );
  }
}

export default AppRoutes;