import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';
import Swal from 'sweetalert2';

const { createSlice } = require('@reduxjs/toolkit');

const AdminFinancerSlice = createSlice({
    name: "AdminFinancer",
    initialState: {
        getFinancerListData: [],
        Status: STATUSES.IDLE,
        FinancerUpdateEmail:[]
    },
    reducers: {
        getFinancerAdminList(state, action) {
            state.getFinancerListData = action.payload
        },
        AdminFinancerStatus(state, action) {
            state.status = action.payload;
        },
        AdminFinancerUpdateEmail(state, action) {
            state.FinancerUpdateEmail = action.payload;
        }
    }
});

export const { getFinancerAdminList, AdminFinancerStatus,AdminFinancerUpdateEmail } = AdminFinancerSlice.actions;
export default AdminFinancerSlice.reducer;


export function getFinancerAdminListAction(data) {
    return async function getFinancerAdminListThunk(dispatch, getState) {
        dispatch(AdminFinancerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "adminfinancer/getfinancerlist", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getFinancerAdminList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    
                    dispatch(AdminFinancerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminFinancerStatus(STATUSES.ERROR));
        }
    }
}

export function AdminFinancerUpdateEmailAction(data) {
    return async function AdminFinancerUpdateEmailThunk(dispatch, getState) {
        dispatch(AdminFinancerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "adminfinancer/updatefinanceremail", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(AdminFinancerUpdateEmail(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.err.sqlMessage)
                    }
                    dispatch(AdminFinancerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminFinancerStatus(STATUSES.ERROR));
        }
    }
}

