import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');


const FinancerDailyReportSlice = createSlice({
    name: "FinancerDailyReport",
    initialState: {
        DailyReportData: [],
        Status: STATUSES.IDLE,
    },
    reducers: {
        getDailyReportData(state, action) {
            state.DailyReportData = action.payload
        },
        dailyReportStatus(state, action) {
            state.status = action.payload;
        }
    }
});

export const { getDailyReportData, dailyReportStatus } = FinancerDailyReportSlice.actions;
export default FinancerDailyReportSlice.reducer;

export function getDailyReportDataAction(data) {
    return async function getDailyReportDataThunk(dispatch, getState) {
        dispatch(dailyReportStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerdailyreport/getdailyreport",data)
                .then(res => {
                    debugger;
                    console.log("getDailyReportData", res)
                    dispatch(getDailyReportData(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(dailyReportStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(dailyReportStatus(STATUSES.ERROR));
        }
    }
}

