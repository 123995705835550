import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');


const FinancerRecoverySlice = createSlice({
    name: "FinancerRecovery",
    initialState: {
        InsertDueDateData: [],
        Status: STATUSES.IDLE,
        RecoveryDatesById: [],
        RecoveryDatesByFinancerIdData: [],
        getEXAccountData:[],
        updateRecoveryStatusData:[]
    },
    reducers: {
        insertDueDate(state, action) {
            state.InsertDueDateData = action.payload
        }, financerRecoveryStatus(state, action) {
            state.status = action.payload;
        }, getRecoveryDatesById(state, action) {
            state.RecoveryDatesById = action.payload;
        }, getRecoveryDatesByFinancerId(state, action) {
            state.RecoveryDatesByFinancerIdData = action.payload;
        }, getAccountData(state, action) {
            state.getEXAccountData = action.payload;
        }, updateRecoveryStatus(state, action) {
            state.updateRecoveryStatusData = action.payload;
        }
    }
});

export const { insertDueDate, financerRecoveryStatus, getRecoveryDatesById, getRecoveryDatesByFinancerId,getAccountData,updateRecoveryStatus } = FinancerRecoverySlice.actions;
export default FinancerRecoverySlice.reducer;

export function insertDueDateAction(data) {
    return async function insertDueDateThunk(dispatch, getState) {
        dispatch(financerRecoveryStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerrecovery/insertduedate", data)
                .then(res => {
                    debugger;
                    console.log("insertduedate", res)
                    dispatch(insertDueDate(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(financerRecoveryStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(financerRecoveryStatus(STATUSES.ERROR));
        }
    }
}

export function getRecoveryDatesByIdAction(data) { /// removed
    return async function getRecoveryDatesByIdThunk(dispatch, getState) {
        dispatch(financerRecoveryStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerrecovery/getdatebyid", data)
                .then(res => {
                    debugger;
                    console.log("getdatebyid", res)
                    dispatch(getRecoveryDatesById(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(financerRecoveryStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(financerRecoveryStatus(STATUSES.ERROR));
        }
    }
}

export function getRecoveryDatesByFinancerIdAction(data) {
    return async function getRecoveryDatesByFinancerIdThunk(dispatch, getState) {
        dispatch(financerRecoveryStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerrecovery/getdatebyfinancerid", data)
                .then(res => {
                    debugger;
                    console.log("getdatebyfinancerid", res)
                    dispatch(getRecoveryDatesByFinancerId(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(financerRecoveryStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(financerRecoveryStatus(STATUSES.ERROR));
        }
    }
}

export function getAccountDataAction(data) {
    return async function getAccountDataThunk(dispatch, getState) {
        dispatch(financerRecoveryStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerrecovery/getaccountdata", data)
                .then(res => {
                    debugger;
                    console.log("getAccountData", res)
                    dispatch(getAccountData(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(financerRecoveryStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(financerRecoveryStatus(STATUSES.ERROR));
        }
    }
}

export function updateRecoveryStatusAction(data) {
    return async function updateRecoveryStatusThunk(dispatch, getState) {
        dispatch(financerRecoveryStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerrecovery/updaterecoverystatus", data)
                .then(res => {
                    debugger;
                    console.log("updateRecoveryStatus", res)
                    dispatch(updateRecoveryStatus(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(financerRecoveryStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(financerRecoveryStatus(STATUSES.ERROR));
        }
    }
}

