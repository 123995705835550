import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import Swal from 'sweetalert2';

import { getCurrentUserInfo, APIBaseURL } from '../Other/Constant'
var dropdownPathsTemp = JSON.parse(sessionStorage.getItem("loginDetails")); // get updated sidebar JSON
console.log("dropdownPathsTemp", dropdownPathsTemp)
console.log("getCurrentUserInfo", APIBaseURL + "user/" + getCurrentUserInfo().photo)
if (dropdownPathsTemp == null) {
  dropdownPathsTemp = [];
} else {
  if (dropdownPathsTemp[0].userTypeName == "SuperAdmin") {
    dropdownPathsTemp = dropdownPathsTemp[1];
  } else {
    if (dropdownPathsTemp[0].isJoinChargesCompleted == 0) { // check join amount approve or not
      let isJoinChargesCompleted = (sessionStorage.getItem("isJoinChargesCompleted")); // get updated sidebar JSON
      if (dropdownPathsTemp[0].isJoinChargesCompleted == 0 && isJoinChargesCompleted == null) {
        Swal.fire("Please add join amount from credit and start your service.")
      } else {
        if (sessionStorage.getItem("isJoinChargesCompleted") != "you_have_access") {
          sessionStorage.setItem("isJoinChargesCompleted", "you_have_access");
        }
      }

      dropdownPathsTemp = dropdownPathsTemp[1].filter((item) => item.menuID == 5 || item.menuID == 12) // dashboard and credit
    } else if (dropdownPathsTemp[0].CreditAccess == "no_access") { // check credit access

      let CreditAccess = (sessionStorage.getItem("CreditAccess")); // get updated sidebar JSON
      if (dropdownPathsTemp[0].CreditAccess == "no_access" && CreditAccess == null) {
        Swal.fire("Please add amount from credit and start your service.")
      } else {
        if (sessionStorage.getItem("CreditAccess") != "you_have_no_credit_access") {
          sessionStorage.setItem("CreditAccess", "you_have_no_credit_access");
        }
      }
      dropdownPathsTemp = dropdownPathsTemp[1].filter((item) => item.menuID == 5 || item.menuID == 12) // dashboard and credit

    } else if (dropdownPathsTemp[0].isJoinChargesCompleted == 1) { /// all access. all is completed
      dropdownPathsTemp = dropdownPathsTemp[1];
    }
  }
}

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();

    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];
    console.log("dropdownPaths", dropdownPaths)
    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    console.log("dropdownPathsTemp", dropdownPathsTemp);

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <Link href="#" className="nav-link" to="profile/">
              <div className="nav-profile-image">
                <img src={APIBaseURL + "uploads/user/" + getCurrentUserInfo().photo} alt=""/>
                <span className="login-status online"></span> {/* change to offline or busy as needed */}
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2"><Trans>{getCurrentUserInfo().fullName}</Trans></span>
                <span className="text-secondary text-small"><Trans>{getCurrentUserInfo().userTypeName}</Trans></span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </Link>
          </li>
          {/* <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title"><Trans>Dashboard</Trans></span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li> */}
          {
            dropdownPathsTemp.map((item) => {
              console.log(item)
              return (<li className={this.isPathActive(item.path) ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to={item.path}>
                  <span className="menu-title"><Trans>{item.menuname}</Trans></span>
                  <ion-icon class="ml-auto" name={item.icon}></ion-icon>
                  {/* <span>{item.icon}</span> */}
                </Link>
              </li>)
            })
          }
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);