import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL, authHeader, Logout_clear } from '../../app/Other/Constant';
import Swal from 'sweetalert2';
import { actions } from 'react-table';

const { createSlice } = require('@reduxjs/toolkit');


//Auth verification code end 

const FinancerCustomerSlice = createSlice({
    name: "FinancerCustomer",
    initialState: {
        InsertCustomerData: [],
        Status: STATUSES.IDLE,
        GetCustomerData: [],
        GetCustomerDataByID: [],
        UpdateCustomerPhoto: [],
        CustomerSearchData: [],
        CustomerUpdateData: [],
        CustomerAccountListData: [],
        GetCustomerDataByIDCopySecond: [],
        CustomerActionExWitnessData:[]
    },
    reducers: {
        insertCustomer(state, action) {
            state.InsertCustomerData = action.payload
        },
        insertCustomerStatus(state, action) {
            state.status = action.payload;
        },
        getCustomer(state, action) {
            state.GetCustomerData = action.payload
        },
        getCustomerByID(state, action) {
            state.GetCustomerDataByID = action.payload
        },
        getCustomerByIDClearStateAction(state, action) {
            state.GetCustomerDataByID = action.payload
        },
        updateCustomerPhotoByID(state, action) {
            state.UpdateCustomerPhoto = action.payload
        },
        CustomerStatus(state, action) {
            state.status = action.payload;
        },
        CustomerSearch(state, action) {
            state.CustomerSearchData = action.payload;
        },
        CustomerUpdate(state, action) {
            state.CustomerUpdateData = action.payload;
        },
        getCustomerAccountList(state, action) {
            state.CustomerAccountListData = action.payload;
        },
        getCustomerByIDCopySecond(state, action) {
            state.GetCustomerDataByIDCopySecond = action.payload
        },
        CustomerActionExWitness(state, action) {
            state.CustomerActionExWitnessData = action.payload
        }
    }
});

export const {
    insertCustomer,
    insertCustomerStatus,
    CustomerStatus,
    getCustomer,
    getCustomerByID,
    updateCustomerPhotoByID,
    getCustomerByIDClearStateAction,
    CustomerSearch,
    CustomerUpdate,
    getCustomerAccountList,
    getCustomerByIDCopySecond,
    CustomerActionExWitness
} = FinancerCustomerSlice.actions;
export default FinancerCustomerSlice.reducer;

export function AddCustomerActionExWitness(data) {
    return async function AddCustomerActionExWitnessThunk(dispatch, getState) {
        dispatch(insertCustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/financercustomeradd", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(CustomerActionExWitness(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.err.sqlMessage)
                    }
                    dispatch(insertCustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertCustomerStatus(STATUSES.ERROR));
        }
    }
}


export function AddCustomerAction(data) {
    return async function AddCustomerThunk(dispatch, getState) {
        dispatch(insertCustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/financercustomeradd", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(insertCustomer(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.err.sqlMessage)
                    }
                    dispatch(insertCustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(insertCustomerStatus(STATUSES.ERROR));
        }
    }
}

export function GetCustomerAction(data) {
    return async function GetCustomerThunk(dispatch, getState) {
        dispatch(CustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/getfinancercustomerlist", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCustomer(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CustomerStatus(STATUSES.ERROR));
        }
    }
}

export function GetCustomerByIDAction(data) {
    return async function GetCustomerThunk(dispatch, getState) {
        dispatch(CustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/getfinancercustomerbyid", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCustomerByID(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CustomerStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateCustomerPhotoAction(data) {
    return async function GetCustomerThunk(dispatch, getState) {
        dispatch(CustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/financercustomeraddphoto", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(updateCustomerPhotoByID(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CustomerStatus(STATUSES.ERROR));
        }
    }
}

export function CustomerSearchAction(data) {
    return async function CustomerSearchThunk(dispatch, getState) {
        dispatch(CustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/financercustomersearch", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(CustomerSearch(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CustomerStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateCustomerAction(data) {
    return async function UpdateCustomerThunk(dispatch, getState) {
        dispatch(CustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/financercustomerupdate", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(CustomerUpdate(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.err.sqlMessage)
                    }
                    dispatch(CustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CustomerStatus(STATUSES.ERROR));
        }
    }
}

export function AddCustomerSearchAction(data) {
    return async function AddCustomerSearchThunk(dispatch, getState) {
        dispatch(CustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/financercustomersearchcustomer", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(insertCustomer(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.err.sqlMessage)
                    }
                    dispatch(CustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CustomerStatus(STATUSES.ERROR));
        }
    }
}

export function getCustomerAccountListAction(data) {
    return async function getCustomerAccountListThunk(dispatch, getState) {
        dispatch(CustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/getcustomeraccountlist", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCustomerAccountList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CustomerStatus(STATUSES.ERROR));
        }
    }
}

export function GetCustomerByIDCopySecondAction(data) {
    return async function GetCustomerCopySecondThunk(dispatch, getState) {
        dispatch(CustomerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercustomer/getfinancercustomerbyid", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCustomerByIDCopySecond(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CustomerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CustomerStatus(STATUSES.ERROR));
        }
    }
}
