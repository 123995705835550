import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');


const FinancerProfileSlice = createSlice({
    name: "FinancerProfile",
    initialState: {
        updateFinancerProfileData: [],
        Status: STATUSES.IDLE,
        FinancerType: [],
        updateFinancerPasswordData: []
    },
    reducers: {
        updateFinancerProfile(state, action) {
            state.updateFinancerProfileData = action.payload
        },
        updateFinancerPassword(state, action) {
            state.updateFinancerPasswordData = action.payload
        },
        ProfileStatus(state, action) {
            state.status = action.payload;
        }
    }
});

export const { updateFinancerProfile, ProfileStatus, updateFinancerPassword } = FinancerProfileSlice.actions;
export default FinancerProfileSlice.reducer;

export function updateFinancerProfileAction(data) {
    return async function updateFinancerProfileThunk(dispatch, getState) {
        dispatch(ProfileStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerprofile/updatefinancerprofile", data)
                .then(res => {
                    debugger;
                    console.log("GetAccountTypeData", res.data)
                    dispatch(updateFinancerProfile(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(ProfileStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(ProfileStatus(STATUSES.ERROR));
        }
    }
}

export function updateFinancerPasswordAction(data) {
    return async function updateFinancerPasswordThunk(dispatch, getState) {
        dispatch(ProfileStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financerprofile/updatefinancerpassword", data)
                .then(res => {
                    debugger;
                    console.log("updateFinancerPassword", res.data)
                    dispatch(updateFinancerPassword(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(ProfileStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(ProfileStatus(STATUSES.ERROR));
        }
    }
}
