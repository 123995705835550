import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const AdminChargesSlice = createSlice({
    name: "AdminCharges",
    initialState: {
        getChargesListData: [],
        Status: STATUSES.IDLE,
        updateChargesData: []
    },
    reducers: {
        getChargesList(state, action) {
            state.getChargesListData = action.payload
        },
        AdminChargesStatus(state, action) {
            state.status = action.payload;
        },
        updateCharges(state, action) {
            state.updateChargesData = action.payload;
        }
    }
});

export const { getChargesList, AdminChargesStatus, updateCharges } = AdminChargesSlice.actions;
export default AdminChargesSlice.reducer;


export function getChargesListAction() {
    return async function getChargesListThunk(dispatch, getState) {
        dispatch(AdminChargesStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "admincharges/getchargeslist")
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getChargesList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(AdminChargesStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminChargesStatus(STATUSES.ERROR));
        }
    }
}

export function updateChargesAction(data) {
    return async function updateChargesThunk(dispatch, getState) {
        dispatch(AdminChargesStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "admincharges/updatecharges",data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(updateCharges(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(AdminChargesStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminChargesStatus(STATUSES.ERROR));
        }
    }
}


