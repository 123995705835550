import React from 'react';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
// import AppRoutes from './AppRoutes';

const BaseAccess = props => (
    <div className="container-scroller">
       <Navbar/>
        <div className="page-body-wrapper">
            <Sidebar/>
            <div className="main-panel">
                <div className="content-wrapper">
                {props.children}
                </div>
                <Footer/>
            </div>
        </div>
    </div>
)

export default BaseAccess;
