import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');


const FinancerCommanSlice = createSlice({
    name: "FinancerComman",
    initialState: {
        GetAccountTypeData: [],
        Status: STATUSES.IDLE,
        FinancerType:[],
        getAccountStatusData:[]
    },
    reducers: {
        getAccountType(state, action) {
            state.GetAccountTypeData = action.payload
        },
        getAccountStatus(state, action) {
            state.getAccountStatusData = action.payload
        },
        commanStatus(state, action) {
            state.status = action.payload;
        },
        GetFinancerType(state,action){
            state.FinancerType = action.payload;
        }
    }
});

export const { getAccountType, commanStatus,GetFinancerType,getAccountStatus } = FinancerCommanSlice.actions;
export default FinancerCommanSlice.reducer;

export function GetAccountTypeAction() {
    return async function GetAccountTypeThunk(dispatch, getState) {
        dispatch(commanStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercomman/getaccounttype")
                .then(res => {
                    debugger;
                    console.log("GetAccountTypeData",res.data)
                    dispatch(getAccountType(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(commanStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(commanStatus(STATUSES.ERROR));
        }
    }
}

export function GetFinancerTypeAction() {
    return async function GetFinancerTypeThunk(dispatch, getState) {
        dispatch(commanStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercomman/getfinancertype")
                .then(res => {
                    debugger;
                    console.log("GetFinancerType",res.data)
                    dispatch(GetFinancerType(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(commanStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(commanStatus(STATUSES.ERROR));
        }
    }
}

export function getAccountStatusAction() {
    return async function getAccountStatusThunk(dispatch, getState) {
        dispatch(commanStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financercomman/getaccountstatus")
                .then(res => {
                    debugger;
                    console.log("getaccountstatus",res.data)
                    dispatch(getAccountStatus(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(commanStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(commanStatus(STATUSES.ERROR));
        }
    }
}
