import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';
import Swal from 'sweetalert2';

const { createSlice } = require('@reduxjs/toolkit');


const FinancerAgentSlice = createSlice({
    name: "FinancerAgent",
    initialState: {
        Status: STATUSES.IDLE,
        GetFinancerAccessListData: [],
        FinancerAgentList: [],
        FinancerAgentDataById: [],
        InsertFinancerAgentData: [],
        UpdateFinancerAgentData: [],
        DeleteFinancerAgentData: []
    },
    reducers: {
        FinancerAgentStatus(state, action) {
            state.Status = action.payload
        },
        GetFinancerAccessList(state, action) {
            state.GetFinancerAccessListData = action.payload
        },
        GetFinancerAgentList(state, action) {
            state.FinancerAgentList = action.payload
        },
        GetFinancerAgentById(state, action) {
            state.FinancerAgentDataById = action.payload
        },
        InsertFinancerAgent(state, action) {
            state.InsertFinancerAgentData = action.payload
        },
        UpdateFinancerAgent(state, action) {
            state.UpdateFinancerAgentData = action.payload
        },
        DeleteFinancerAgent(state, action) {
            state.DeleteFinancerAgentData = action.payload
        }
    }
});

export const { FinancerAgentStatus, GetFinancerAccessList, GetFinancerAgentList, GetFinancerAgentById, InsertFinancerAgent, UpdateFinancerAgent, DeleteFinancerAgent } = FinancerAgentSlice.actions;
export default FinancerAgentSlice.reducer;

export function GetFinancerAccessListAction() {
    return async function GetFinancerAccessListThunk(dispatch, getState) {
        dispatch(FinancerAgentStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeragent/getfinanceraccess")
                .then(res => {
                    debugger;
                    console.log("getfinanceraccess", res.data)
                    dispatch(GetFinancerAccessList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(FinancerAgentStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerAgentStatus(STATUSES.ERROR));
        }
    }
}

export function GetFinancerAgentListAction(data) {
    return async function GetFinancerAgentListThunk(dispatch, getState) {
        dispatch(FinancerAgentStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeragent/getfinanceragent", data)
                .then(res => {
                    debugger;
                    console.log("getfinanceragent", res.data)
                    dispatch(GetFinancerAgentList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(FinancerAgentStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerAgentStatus(STATUSES.ERROR));
        }
    }
}

export function GetFinancerAgentByIdAction(data) {
    return async function GetFinancerAgentByIdThunk(dispatch, getState) {
        dispatch(FinancerAgentStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeragent/getfinanceragentbyid", data)
                .then(res => {
                    debugger;
                    console.log("GetFinancerAgentById", res.data)
                    dispatch(GetFinancerAgentById(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(FinancerAgentStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerAgentStatus(STATUSES.ERROR));
        }
    }
}


export function InsertFinancerAgentAction(data) {
    return async function InsertFinancerAgentThunk(dispatch, getState) {
        dispatch(FinancerAgentStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeragent/addfinanceragent", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(InsertFinancerAgent(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.sqlMessage)
                    }
                    dispatch(FinancerAgentStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerAgentStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateFinancerAgentAction(data) {
    return async function UpdateFinancerAgentThunk(dispatch, getState) {
        dispatch(FinancerAgentStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeragent/updatefinanceragent", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(UpdateFinancerAgent(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.err.sqlMessage)
                    }
                    dispatch(FinancerAgentStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerAgentStatus(STATUSES.ERROR));
        }
    }
}

export function DeleteFinancerAgentAction(data) {
    return async function DeleteFinancerAgentThunk(dispatch, getState) {
        dispatch(FinancerAgentStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeragent/deletefinanceragent", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(DeleteFinancerAgent(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(FinancerAgentStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerAgentStatus(STATUSES.ERROR));
        }
    }
}
