import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const AdminAgentSlice = createSlice({
    name: "AdminAgent",
    initialState: {
        getAgentListData: [],
        Status: STATUSES.IDLE,
    },
    reducers: {
        getAgentAdminList(state, action) {
            state.getAgentListData = action.payload
        },
        AdminAgentStatus(state, action) {
            state.status = action.payload;
        }
    }
});

export const { getAgentAdminList, AdminAgentStatus } = AdminAgentSlice.actions;
export default AdminAgentSlice.reducer;


export function getAgentAdminListAction() {
    return async function getAgentAdminListThunk(dispatch, getState) {
        dispatch(AdminAgentStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "adminagent/getagentlist")
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getAgentAdminList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(AdminAgentStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminAgentStatus(STATUSES.ERROR));
        }
    }
}


