import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const AdminDashboardSlice = createSlice({
    name: "AdminDashboard",
    initialState: {
        getLowCreditFinancerListData: [],
        Status: STATUSES.IDLE,
        ADMDashboardDuplicateRecordData: []
    },
    reducers: {
        getLowCreditFinancerListAdminList(state, action) {
            state.getLowCreditFinancerListData = action.payload
        },
        AdminDashboardStatus(state, action) {
            state.status = action.payload;
        },
        AdminDashboardDuplicateRecords(state, action) {
            state.ADMDashboardDuplicateRecordData = action.payload;
        }
    }
});

export const { getLowCreditFinancerListAdminList, AdminDashboardStatus, AdminDashboardDuplicateRecords } = AdminDashboardSlice.actions;
export default AdminDashboardSlice.reducer;


export function getLowCreditFinancerListAction() {
    return async function getLowCreditFinancerListThunk(dispatch, getState) {
        dispatch(AdminDashboardStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "admindashboard/getlowcreditfinancerlist")
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getLowCreditFinancerListAdminList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(AdminDashboardStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminDashboardStatus(STATUSES.ERROR));
        }
    }
}
export function getDuplicateRecordsAction() {
    return async function getLowCreditFinancerListThunk(dispatch, getState) {
        dispatch(AdminDashboardStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "admindashboard/getduplicaterecords")
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(AdminDashboardDuplicateRecords(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(AdminDashboardStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminDashboardStatus(STATUSES.ERROR));
        }
    }
}

