import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const FinanceCreditSlice = createSlice({
    name: "FinanceCredit",
    initialState: {
        Status: STATUSES.IDLE,
        AllCreditHistoryByIdData: [],
        insertCashCreditData: [],
        CreditCountData: [],
        FinacerByCodeData: [],
        transferToFinancerAmountData:[]
    },
    reducers: {
        getAllCreditHistoryById(state, action) {
            state.AllCreditHistoryByIdData = action.payload
        },
        CreditStatus(state, action) {
            state.status = action.payload;
        },
        insertCashCredit(state, action) {
            state.insertCashCreditData = action.payload;
        },
        getCreditCount(state, action) {
            state.CreditCountData = action.payload;
        },
        getFinacerByCode(state, action) {
            state.FinacerByCodeData = action.payload;
        },
        transferToFinancerAmount(state,action){
            state.transferToFinancerAmountData = action.payload;
        }
    }
});

export const { CreditStatus, getAllCreditHistoryById, insertCashCredit, getCreditCount, getFinacerByCode,transferToFinancerAmount } = FinanceCreditSlice.actions;
export default FinanceCreditSlice.reducer;

export function getAllCreditHistoryByIdAction(data) {
    return async function getAllCreditHistoryByIdThunk(dispatch, getState) {
        dispatch(CreditStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecredit/getcredithistoryoffinancer", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getAllCreditHistoryById(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(CreditStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CreditStatus(STATUSES.ERROR));
        }
    }
}


export function insertCashCreditAction(data) {
    return async function insertCashCreditThunk(dispatch, getState) {
        dispatch(CreditStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecredit/insertcashcredit", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(insertCashCredit(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CreditStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CreditStatus(STATUSES.ERROR));
        }
    }
}

export function getCreditCountAction(data) {
    return async function getCreditCountThunk(dispatch, getState) {
        dispatch(CreditStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecredit/getusercreditcount", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCreditCount(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CreditStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CreditStatus(STATUSES.ERROR));
        }
    }
}

export function getFinancerByCodeAction(data) {
    return async function getFinancerByCodeThunk(dispatch, getState) {
        dispatch(CreditStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecredit/getfinancerbycode", data)
                .then(res => {
                    debugger;
                    console.log(res)
                    dispatch(getFinacerByCode(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CreditStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CreditStatus(STATUSES.ERROR));
        }
    }
}

export function transferToFinancerAmountAction(data) {
    return async function transferToFinancerAmountThunk(dispatch, getState) {
        dispatch(CreditStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financecredit/transfertofinanceramount", data)
                .then(res => {
                    debugger;
                    console.log(res)
                    dispatch(transferToFinancerAmount(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(CreditStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(CreditStatus(STATUSES.ERROR));
        }
    }
}
