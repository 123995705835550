import React, { Component } from 'react';

class Footer extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 footerA">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <span className='orangeText'>YouConnect</span></span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Designed and Developed by <a href="https://www.facebook.com/umesh.waykar.9" target='_blank'>Umesh Waykar</a> and <a href="#">Subhash Titkare</a></span>
        </div>
      </footer> 
    );
  }
}

export default Footer;