import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');

const AdminAdminCashRequestSlice = createSlice({
    name: "AdminCashRequest",
    initialState: {
        getCashRequestListData: [],
        Status: STATUSES.IDLE,
        CashRequestByIdData: [],
        updateCashRequestData: []
    },
    reducers: {
        getCashRequestList(state, action) {
            state.getCashRequestListData = action.payload
        },
        getCashRequestById(state, action) {
            state.CashRequestByIdData = action.payload
        },
        AdminCashRequestStatus(state, action) {
            state.status = action.payload;
        },
        updateCashRequest(state, action) {
            state.updateCashRequestData = action.payload;
        }
    }
});

export const { getCashRequestList, AdminCashRequestStatus, getCashRequestById, updateCashRequest } = AdminAdminCashRequestSlice.actions;
export default AdminAdminCashRequestSlice.reducer;


export function getCashRequestAction(data) {
    // alert("req")
    return async function getCashRequestThunk(dispatch, getState) {
        dispatch(AdminCashRequestStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "admincashrequest/getcashrequestlist", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCashRequestList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(AdminCashRequestStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminCashRequestStatus(STATUSES.ERROR));
        }
    }
}


export function updateCashRequestAction(data) {
    return async function updateCashRequestThunk(dispatch, getState) {
        dispatch(AdminCashRequestStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "admincashrequest/updatecashrequest", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(updateCashRequest(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(AdminCashRequestStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminCashRequestStatus(STATUSES.ERROR));
        }
    }
}



export function getCashRequestByIdAction(data) {
    return async function getCashRequestByIdThunk(dispatch, getState) {
        dispatch(AdminCashRequestStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "admincashrequest/getcashrequestbyid", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getCashRequestById(res.data))
                })
                .catch(err => {
                    console.log(err)
                    // if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                    //     alert(err.response.data.err.sqlMessage)
                    // }
                    dispatch(AdminCashRequestStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(AdminCashRequestStatus(STATUSES.ERROR));
        }
    }
}
