import { STATUSES, LOGIN_STATUSES } from '../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../app/Other/Constant';

const { createSlice } = require('@reduxjs/toolkit');



const SendMailSlice = createSlice({
    name: "SendMail",
    initialState: {
        sendMailResponce: [],
        MailStatus: STATUSES.IDLE
    },
    reducers: {
        sendMail(state, action) {
            state.sendMailResponce = action.payload
        },
        setStatus(state, action) {
            state.MailStatus = action.payload;
        },
    }
});

export const { sendMail, setStatus } = SendMailSlice.actions;
export default SendMailSlice.reducer;


export function sendMailAction(data) {
    return async function sendMailThunk(dispatch, getState) {
        dispatch(setStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financermailsend/sendmail", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(sendMail(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(setStatus(STATUSES.ERROR));
        }
    }
}