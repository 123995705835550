import Cryptr from 'cryptr';

// export const APIBaseURL = "http://localhost:4242/";
export const APIBaseURL = process.env.REACT_APP_API_URL;

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

export const LOGIN_STATUSES = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error',
    FAILED: 'failed',
    IDLE: 'idle'
});


export function validateEmail(email) {
    var validEmail = false;
    if (email) {
        email = email.trim().toLowerCase();
        var pattern = /^[\w-']+(\.[\w-']+)*@([a-zA-Z0-9]+[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*?\.[a-zA-Z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
        validEmail = pattern.exec(email);
    }
    return validEmail;
}
export function ValidationPAN(PAN) {
    let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regpan.test(PAN)) {
        return true;
    } else {
        return false;
    }
}

export function getCurrentUserInfo() {
    var currentUser = JSON.parse(sessionStorage.getItem("loginDetails"));
    if (currentUser != null) {
        return currentUser[0];
    } else {
        return false;
    }
}

export function generateOTP() {
    // Declare a digits variable 
    // which stores all digits
    var digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 4; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
}

export function isPositiveInteger(n) {
    return parseFloat(n) === n >>> 0;
}

export function authHeader() {
    const currentUserToken = JSON.parse(sessionStorage.getItem('loginDetails'));
    console.log("currentUserToken", currentUserToken)
    if (currentUserToken != null) {
        if (currentUserToken[2]['token']) {
            return { 'x-access-token': currentUserToken[2].token };
        } else {
            return false;
        }
    } else {
        return false;
    }
    //console.log("Auth use",user[0].toekn);
    // if (user && user[0] != undefined && user[0].token) {
    //     // for Node.js Express back-end
    //     return { 'x-access-token': user[0].token };
    // } else {
    //     return {};
    // }
}


export function Logout_clear() {
    sessionStorage.removeItem("loginDetails");
    sessionStorage.removeItem("isJoinChargesCompleted");
    sessionStorage.removeItem("CreditAccess");
    return true;
}

export function CryptrIdCheck(Token, Type) {
    const cryptr = new Cryptr(process.env.REACT_APP_URL_CODE);
    if (Type == "encryption") {
        return cryptr.encrypt(Token);
    } else if (Type == "decryption") {
        try {
            return cryptr.decrypt(Token);
        } catch (error) {
            return false;
        }
    } else {
        return false;
    }
}
