import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';
import Swal from 'sweetalert2';

const { createSlice } = require('@reduxjs/toolkit');


const FinancerAccountSlice = createSlice({
    name: "FinancerAccount",
    initialState: {
        AccountCustomerByCodeData: [],
        WitnessOneData: [],
        WitnessTwoData: [],
        AddAccountData: [],
        GetAccountListData: [],
        Status: STATUSES.IDLE,
        AccountTransactionDataByIdList: [],
        AccountDataByAccountCode: [],
        EditAccountData: [],
        DeleteAccountData: []
    },
    reducers: {
        getAccountCustomerByCode(state, action) {
            state.AccountCustomerByCodeData = action.payload
        },
        getWitnessOneData(state, action) {
            state.WitnessOneData = action.payload
        },
        getWitnessTwoData(state, action) {
            state.WitnessTwoData = action.payload
        },
        accountStatus(state, action) {
            state.status = action.payload;
        },
        AddAccount(state, action) {
            state.AddAccountData = action.payload;
        },
        GetAllAccountList(state, action) {
            state.GetAccountListData = action.payload;
        },
        GetAccountTransactionDataByIdList(state, action) {
            state.AccountTransactionDataByIdList = action.payload;
        },
        GetAccountDataByAccountCode(state, action) {
            state.AccountDataByAccountCode = action.payload;
        },
        EditAccount(state, action) {
            state.EditAccountData = action.payload;
        },
        DeleteAccount(state, action) {
            state.DeleteAccountData = action.payload;
        }
    }
});

export const { getAccountCustomerByCode,
    accountStatus,
    getWitnessOneData,
    getWitnessTwoData,
    AddAccount,
    GetAllAccountList,
    GetAccountTransactionDataByIdList,
    GetAccountDataByAccountCode,
    EditAccount,
    DeleteAccount
} = FinancerAccountSlice.actions;
export default FinancerAccountSlice.reducer;

export function getAccountCustomerByCodeAction(data, type = "Customer") {
    return async function getAccountCustomerThunk(dispatch, getState) {
        dispatch(accountStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeraccount/getcustomerdatabycode", data)
                .then(res => {
                    debugger;
                    console.log("getAccountCustomerByCode", res)
                    if (type == "Customer") {
                        dispatch(getAccountCustomerByCode(res.data))
                    } else if (type == "WitnessOne") {
                        dispatch(getWitnessOneData(res.data))
                    } else if (type == "WitnessTwo") {
                        dispatch(getWitnessTwoData(res.data))
                    }
                })
                .catch(err => {
                    console.log(err)
                    dispatch(accountStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(accountStatus(STATUSES.ERROR));
        }
    }
}

export function AddAccountAction(data) {
    return async function AddAccountThunk(dispatch, getState) {
        dispatch(accountStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeraccount/addaccount", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(AddAccount(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.err.sqlMessage)
                    }
                    dispatch(accountStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(accountStatus(STATUSES.ERROR));
        }
    }
}



export function getAllAccountAction(data) {
    return async function getAllAccountThunk(dispatch, getState) {
        dispatch(accountStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeraccount/getaccountlist", data)
                .then(res => {
                    debugger;
                    dispatch(GetAllAccountList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(accountStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(accountStatus(STATUSES.ERROR));
        }
    }
}


export function GetAccountTransactionDataByIdListAction(data) {
    return async function GetAccountTransactionDataByIdListThunk(dispatch, getState) {
        dispatch(accountStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeraccount/getaccounttransactiondatabyid", data)
                .then(res => {
                    debugger;
                    console.log(res)
                    dispatch(GetAccountTransactionDataByIdList(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(accountStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(accountStatus(STATUSES.ERROR));
        }
    }
}

export function GetAccountDataByAccountCodeAction(data) {
    return async function GetAccountDataByAccountCodeThunk(dispatch, getState) {
        dispatch(accountStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeraccount/getaccountdatabyaccountcode", data)
                .then(res => {
                    debugger;
                    console.log(res)
                    dispatch(GetAccountDataByAccountCode(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(accountStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(accountStatus(STATUSES.ERROR));
        }
    }
}


export function EditAccountAction(data) {
    return async function EditAccountThunk(dispatch, getState) {
        dispatch(accountStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeraccount/editaccount", data)
                .then(res => {
                    debugger;
                    console.log(res)
                    dispatch(EditAccount(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(accountStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(accountStatus(STATUSES.ERROR));
        }
    }
}


export function DeleteAccountAction(data) {
    return async function DeleteAccountThunk(dispatch, getState) {
        dispatch(accountStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financeraccount/deleteaccount", data)
                .then(res => {
                    debugger;
                    console.log(res)
                    dispatch(DeleteAccount(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(accountStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(accountStatus(STATUSES.ERROR));
        }
    }
}
