import { STATUSES } from '../../app/Other/Constant.js';
import axios from 'axios';
import { APIBaseURL } from '../../app/Other/Constant';
import Swal from 'sweetalert2';

const { createSlice } = require('@reduxjs/toolkit');


const FinanceFinancerSlice = createSlice({
    name: "FinanceFinancer",
    initialState: {
        InsertFinancerData: [],
        Status: STATUSES.IDLE,
        GetFinancerListByIdData: [],
        getUserByID: [],
        UpdateUserPhotoByID:[]
    },
    reducers: {
        insertFinancer(state, action) {
            state.InsertFinancerData = action.payload
        },
        FinancerStatus(state, action) {
            state.status = action.payload;
        },
        getFinancerListById(state, action) {
            state.GetFinancerListByIdData = action.payload;
        },
        getFinancerById(state, action) {
            state.getUserByID = action.payload;
        },
        updateUserPhotoByID(state, action) {
            state.UpdateUserPhotoByID = action.payload
        }
    }
});

export const { insertFinancer, FinancerStatus, getFinancerListById, getFinancerById, updateUserPhotoByID } = FinanceFinancerSlice.actions;
export default FinanceFinancerSlice.reducer;


export function AddFinancerAction(data) {
    return async function AddFinancerThunk(dispatch, getState) {
        dispatch(FinancerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financefinancer/financefinanceradd", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(insertFinancer(res.data))
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.err.code === 'ER_DUP_ENTRY') {
                        Swal.fire(err.response.data.err.sqlMessage)
                    }
                    dispatch(FinancerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerStatus(STATUSES.ERROR));
        }
    }
}


export function getFinancerListByIdAction(data) {
    return async function getFinancerListByIdThunk(dispatch, getState) {
        dispatch(FinancerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financefinancer/getfinancerlistbyid", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getFinancerListById(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(FinancerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerStatus(STATUSES.ERROR));
        }
    }
}


export function getFinancerByIdAction(data) {
    return async function getFinancerByIdThunk(dispatch, getState) {
        dispatch(FinancerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financefinancer/getfinancerbyid", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(getFinancerById(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(FinancerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerStatus(STATUSES.ERROR));
        }
    }
}

export function UpdateUserPhotoAction(data) {
    return async function UpdateUserPhotoThunk(dispatch, getState) {
        dispatch(FinancerStatus(STATUSES.LOADING));
        try {
            axios
                .post(APIBaseURL + "financefinancer/financefinanceraddphoto", data)
                .then(res => {
                    debugger;
                    console.log(res.data)
                    dispatch(updateUserPhotoByID(res.data))
                })
                .catch(err => {
                    console.log(err)
                    dispatch(FinancerStatus(STATUSES.ERROR));
                })
        } catch (error) {
            console.log(error)
            dispatch(FinancerStatus(STATUSES.ERROR));
        }
    }
}
