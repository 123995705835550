import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './app/App';
import Store from './store/store';
import AppRoutes from './app/AppRoutes';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import './style.css';
ReactDOM.render(
  <HashRouter>
    <Provider store={Store}>
      <AppRoutes />
    </Provider>
  </HashRouter>
  , document.getElementById('root'));

serviceWorker.unregister();